import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CssBaseline, SvgIcon, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { NoteAdd, PictureAsPdf, WidthFull } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#29648A',
    },
    secondary: {
      main: '#2e9cca',
    },
    background: {
      default: '#25274D',
      paper: '#464866',
    },
    text: {
      primary: '#E1E1F3',
      secondary: '#AAABB8',
    }
  },
});

export interface ActionAreaCardProps {
  primaryText: string,
  secondaryText: string,
  page: string,
  component?: React.ReactNode 
  width?: number
}

export default function ActionAreaCard(props:ActionAreaCardProps) {

  const navigate = useNavigate();
  const primaryText = props.primaryText;
  const secondaryText = props.secondaryText;
  const component = props.component;
  const width = props.width ?  props.width : 345

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
    <Card sx={{ maxWidth: width, borderRadius:5}}>
      <CardActionArea
      onClick={() => navigate(props.page)}>
        <Box sx={{ p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              { primaryText } 
            </Typography>
            <Typography variant="body2" color="text.secondary">
              { secondaryText }
            </Typography>
            {
              component && <div>{component}</div>
            }
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
    </ThemeProvider>
  );
}