import React, { useState , useEffect, useDeferredValue } from 'react';
import Base64Downloader from 'react-base64-downloader';
import { useLocation, useNavigate } from 'react-router-dom';
import './CreateProposal.css'
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    createTheme,
    CssBaseline,
    ThemeProvider,
    Box
  } from '@mui/material';

function CreateProposal() {

    const navigate = useNavigate();

    const location = useLocation();
    let customer = JSON.parse(location.state);

    const theme = createTheme({
        palette: {
          primary: {
            main: '#29648A',
          },
          secondary: {
            main: '#2e9cca',
          },
          background: {
            default: '#25274D',
            paper: '#464866',
          },
          text: {
            primary: '#E1E1F3',
            secondary: '#AAABB8',
          }
        },
      });

    useEffect(() =>
        {
            if(!("token" in sessionStorage) || sessionStorage.getItem('token') != process.env.REACT_APP_TOKEN) {
                console.log("Token not found")
                navigate("/login");
            }
        }
    )

    const[customerName, setCustomerName] = useState(customer?.customerName);
    const[customerAddress,setCustomerAddress] = useState(customer?.customerAddress);
    const[areaRennovated,setAreaRennovate] = useState('');
    const[areaOfConcreteInstallation, setAreaOfConcreteInstallation] = useState(0);
    const[areaOfDemolition, setAreaOfDemolition] = useState(0);
    const[thicknessOfConcrete, setThicknessOfConcrete] = useState('');
    const[concretePSIStrength, setConcretePSIStrength] = useState(0);
    const[controlJointMaterial, setControlJointMaterial] = useState('');
    const[rebarNumber, setRebarNumber] = useState(0);
    const[rebarSpacing,setRebarSpacing] = useState(0);
    const[surfaceFinishing, setSurfaceFinishing] = useState('');
    const[additionalNotes, setAdditionalNotes] = useState('');
    const[installationCost, setInstallationCost] = useState(0);
    const[isDemolitionIncluded, setIsDemolitionIncluded] = useState(true);
    const[demolitionCost, setDemolitionCost] = useState(0);
    const[isDisposalIncluded, setIsDisposalIncluded] = useState(true);
    const[disposalCost, setDisposalCost] = useState(0);
    const[isHoaAssistanceIncluded, setIsHoaAssistanceIncluded] = useState(true);
    const[hoaAssistanceCost, setHoaAssistanceCost] = useState(0);
    const[hasSpecialDesign, setHasSpecialDesign] = useState(false);
    const[specialDesignCost, setSpecialDesignCost] = useState(0);
    const[projectDuration, setProjectDuration] = useState('');
    const[isFinanced,setIsFinanced] = useState(false);
    const[monthlyCost, setMonthlyCost] = useState(0);
    const[isDiscount, setIsDiscount] = useState(false);
    const[discountType, setDiscountType] = useState('');
    const[isOtherDiscount, setIsOtherDiscount] = useState(false);
    const[discountAmount, setDiscountAmount] = useState('');
    const[hasTentativeDates, setHasTentativeDates] = useState(false);
    const[startDate, setStartDate] = useState(new Date());
    const[endDate, setEndDate] = useState(new Date());
    const[canSubmit, setCanSubmit] = useState(true);
    var fileList = [];
    //const[proposalFile, setProposalFile] = useState('');

    const onNameChange = e => {
        setCustomerName(e.target.value);
    }

    const onAddressChange = e => {
        setCustomerAddress(e.target.value);
    }

    const onAreaRennovateChange = e => {
        setAreaRennovate(e.target.value);
    }

    const onAreaOfConcreteInstallationChange = e => {
        setAreaOfConcreteInstallation(e.target.value);
    }

    const onAreaOfDemolitionChange = e => {
        setAreaOfDemolition(e.target.value);
    }

    const onThicknessOfConcreteChange = e => {
        setThicknessOfConcrete(e.target.value);
    }

    const onConcretePsiChange = e => {
        setConcretePSIStrength(e.target.value);
    }

    const onControlJointMaterialChange = e => {
        setControlJointMaterial(e.target.value);
    }

    const onRebarNumberChange = e => {
        setRebarNumber(e.target.value);
    }

    const onRebarSpacingChange = e => {
        setRebarSpacing(e.target.value);
    }

    const onSurfaceFinishingChange = e => {
        setSurfaceFinishing(e.target.value);
    }

    const onAdditionalNotesChange = e => {
        setAdditionalNotes(e.target.value);
    }

    const onInstallationCostChange = e => {
        setInstallationCost(e.target.value);
    }
    
    const onIsDemolitionIncludedChange = e => {
        setIsDemolitionIncluded(!isDemolitionIncluded);
    }

    const onDemolitionCostChange = e => {
        setDemolitionCost(e.target.value);
    }

    const onIsDisposalIncludedChange = e => {
        setIsDisposalIncluded(!isDisposalIncluded);
    }

    const onDisposalCostChange = e => {
        setDisposalCost(e.target.value);
    }

    const onIsHoaAssistanceIncluded = e => {
        setIsHoaAssistanceIncluded(!isHoaAssistanceIncluded);
    }

    const onHoaAssistanceCostChange = e => {
        setHoaAssistanceCost(e.target.value);
    }

    const onHasSpecialDesignChange = e => {
        setHasSpecialDesign(!hasSpecialDesign);
    }

    const onSpecialDesignCostChange = e => {
        setSpecialDesignCost(e.target.value);
    }

    const onProjectDurationChange = e => {
        setProjectDuration(e.target.value);
    }

    const onIsFinanceChanged = e => {
        setIsFinanced(!isFinanced);
    }

    const onMonthlyCostChange = e => {
        setMonthlyCost(e.target.value);
    }

    const onIsDicountChange = e => {
        setIsDiscount(!isDiscount);
        console.log(e.target.value);
    }

    const onDicountTypeChange = e => {
        const discountType = e.target.value;
        setDiscountType(e.target.value);
        if(discountType == 'other'){
            setIsOtherDiscount(true);
        }
        else{
            setIsOtherDiscount(false);
        }
    }

    const onOtherDiscountTypeChange = e => {
        setDiscountType(e.target.value);
    }

    const onDiscountAmountChange = e=> {
        setDiscountAmount(e.target.value);
    }

    const onHasTentativeDatesChange = e => {
        setHasTentativeDates(!hasTentativeDates);
        console.log(hasTentativeDates);
    }

    const onStartDateChange = e => {
        setStartDate(e.target.value);
    }

    const onEndDateChange = e => {
        setEndDate(e.target.value);
    }


    const imageUploaded = async e => {
        const files = e.target.files;

        setCanSubmit(false);

        let fileUploadPromise =  new Promise((resolve, reject) => {
            for (var i = 0; i < e.target.files.length; i++) {

                const reader = new FileReader();

                reader.onload = (event) => {
                    resolve(event.target.result);
                    var results = reader.result;
                    var filteredResult = results.split("base64,")[1];
                    fileList.push(filteredResult);
                };

                reader.onerror = (err) => {
                    reject(err);
                };
                reader.readAsDataURL(files[i]);
            }

            setCanSubmit(true);
        });

        return await fileUploadPromise;

    }

    async function createProposal() {
        try {
            const response = await fetch(process.env.REACT_APP_CREATE_PROPOSAL, {
                method: "POST",
                headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "application/json",
                },
                body: JSON.stringify({
                    "customerName": customerName,
                    "customerAddress": customerAddress,
                    "areaRennovated": areaRennovated,
                    "areaOfConcreteInstallation": areaOfConcreteInstallation,
                    "areaOfDemolition": areaOfDemolition,
                    "thicknessOfConcrete": thicknessOfConcrete,
                    "concretePSIStrength": concretePSIStrength,
                    "controlJointMaterial": controlJointMaterial,
                    "rebarNumber": rebarNumber,
                    "rebarSpacing": rebarSpacing,
                    "surfaceFinishing":surfaceFinishing,
                    "additionalNotes":additionalNotes,
                    "installationCost":installationCost,
                    "isDemolitionIncluded":isDemolitionIncluded,
                    "demolitionCost":demolitionCost,
                    "isDisposalIncluded":isDisposalIncluded,
                    "disposalCost":disposalCost,
                    "isHoaAssistanceIncluded":isHoaAssistanceIncluded,
                    "hoaAssistanceCost":hoaAssistanceCost,
                    "hasSpecialDesign":hasSpecialDesign,
                    "specialDesignCost":specialDesignCost,
                    "projectDuration":projectDuration,
                    "isFinance": isFinanced,
                    "monthlyCost": monthlyCost,
                    "isDiscount":isDiscount,
                    "discountAmount":discountAmount,
                    "discountType": discountType,
                    "hasTentativeDates":hasTentativeDates,
                    "startDate":startDate,
                    "endDate": endDate,
                    "imageByteArray": fileList
                }),
            });
            const responseJson = await response.json();

            let proposalFile = responseJson.encodedPdf;

            const linkSource = `data:application/pdf;base64,${proposalFile}`;
            const downloadLink = document.createElement("a");
            const fileName = "ZGConcreteProposal-" + customerName + ".pdf" ;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } catch(error) {
            console.error(error);
        }
    }


    /*
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box
            component="form"
            sx={{ '& .MuiTextField-root': { marginRight: 2, width: '50ch' }, '& .MuiFormControlLabel-root': { width : '50ch' },
            marginTop: 5, marginLeft: 5, width:500}}
            noValidate
            autoComplete="off"
            >
        <div>
          <h1>Create Proposal</h1>
          <form>
            <TextField
              label="Full Name"
              variant="outlined"
              className="create-proposal-string"
              value={customerName}
              onChange={onNameChange}
              placeholder="John Smith"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Address"
              variant="outlined"
              className="create-proposal-address"
              value={customerAddress}
              onChange={onAddressChange}
              placeholder="123 Main Street, Anytown, USA 12345"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Area Being Renovated"
              variant="outlined"
              className="create-proposal-string"
              value={areaRennovated}
              onChange={onAreaRennovateChange}
              placeholder="patio"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Square footage of concrete installation"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={areaOfConcreteInstallation}
              onChange={onAreaOfConcreteInstallationChange}
              placeholder="123"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Square footage of demolition/excavation"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={areaOfDemolition}
              onChange={onAreaOfDemolitionChange}
              placeholder="123"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Thickness of concrete slab"
              variant="outlined"
              className="create-proposal-numeric"
              value={thicknessOfConcrete}
              onChange={onThicknessOfConcreteChange}
              placeholder="1 In."
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Concrete mix PSI strength"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={concretePSIStrength}
              onChange={onConcretePsiChange}
              placeholder="1234"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Control joint material"
              variant="outlined"
              className="create-proposal-string"
              value={controlJointMaterial}
              onChange={onControlJointMaterialChange}
              placeholder="Treated Wood"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Rebar number/sizing"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={rebarNumber}
              onChange={onRebarNumberChange}
              placeholder="1"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Rebar spacing"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={rebarSpacing}
              onChange={onRebarSpacingChange}
              placeholder="12"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Surface Finishing"
              variant="outlined"
              className="create-proposal-string"
              value={surfaceFinishing}
              onChange={onSurfaceFinishingChange}
              placeholder="Moderate Slip Resistance"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Additional Notes"
              variant="outlined"
              className="create-proposal-string"
              value={additionalNotes}
              onChange={onAdditionalNotesChange}
              placeholder="3YDS"
              fullWidth
              margin="normal"
            />
    
            <TextField
              label="Concrete Slab Installation Cost"
              variant="outlined"
              className="create-proposal-numeric"
              type="number"
              value={installationCost}
              onChange={onInstallationCostChange}
              placeholder="1234"
              fullWidth
              margin="normal"
            />
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDemolitionIncluded}
                  onChange={onIsDemolitionIncludedChange}
                />
              }
              label="Is Demolition Included"
            />
    
            {!isDemolitionIncluded && (
              <TextField
                label="Demolition Cost"
                variant="outlined"
                className="create-proposal-numeric"
                type="number"
                value={demolitionCost}
                onChange={onDemolitionCostChange}
                placeholder="1234"
                fullWidth
                margin="normal"
              />
            )}
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDisposalIncluded}
                  onChange={onIsDisposalIncludedChange}
                />
              }
              label="Is Disposal Included"
            />
    
            {!isDisposalIncluded && (
              <TextField
                label="Disposal Cost"
                variant="outlined"
                className="create-proposal-numeric"
                type="number"
                value={disposalCost}
                onChange={onDisposalCostChange}
                placeholder="1234"
                fullWidth
                margin="normal"
              />
            )}
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={isHoaAssistanceIncluded}
                  onChange={onIsHoaAssistanceIncluded}
                />
              }
              label="Is HOA Assistance Included"
            />
    
            {!isHoaAssistanceIncluded && (
              <TextField
                label="HOA Assistance Cost"
                variant="outlined"
                className="create-proposal-numeric"
                type="number"
                value={hoaAssistanceCost}
                onChange={onHoaAssistanceCostChange}
                placeholder="1234"
                fullWidth
                margin="normal"
              />
            )}
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasSpecialDesign}
                  onChange={onHasSpecialDesignChange}
                />
              }
              label="Has Design/Special Requests"
            />
    
            {hasSpecialDesign && (
              <TextField
                label="Special Design Cost"
                variant="outlined"
                className="create-proposal-numeric"
                type="number"
                value={specialDesignCost}
                onChange={onSpecialDesignCostChange}
                placeholder="1234"
                fullWidth
                margin="normal"
              />
            )}
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFinanced}
                  onChange={onIsFinanceChanged}
                />
              }
              label="Is Financed"
            />
    
            {isFinanced && (
              <TextField
                label="Monthly Payment"
                variant="outlined"
                className="create-proposal-numeric"
                type="number"
                value={monthlyCost}
                onChange={onMonthlyCostChange}
                placeholder="1234"
                fullWidth
                margin="normal"
              />
            )}
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDiscount}
                  onChange={onIsDicountChange}
                />
              }
              label="Give Discount"
            />
    
            {isDiscount && (
              <div>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Discount Type</InputLabel>
                  <Select
                    value={discountType}
                    onChange={onDicountTypeChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="Friends & Family">Friends and Family</MenuItem>
                    <MenuItem value="Military">Military</MenuItem>
                    <MenuItem value="Referral">Referral</MenuItem>
                  </Select>
                </FormControl>
    
                {isOtherDiscount && (
                  <TextField
                    label="Other Discount Type"
                    variant="outlined"
                    className="create-proposal-string"
                    value={discountType}
                    onChange={onOtherDiscountTypeChange}
                    fullWidth
                    margin="normal"
                  />
                )}
    
                <TextField
                  label="Discount Amount"
                  variant="outlined"
                  className="create-proposal-numeric"
                  type="number"
                  value={discountAmount}
                  onChange={onDiscountAmountChange}
                  placeholder="1234"
                  fullWidth
                  margin="normal"
                />
              </div>
            )}
    
            <TextField
              label="Project Duration"
              variant="outlined"
              className="create-proposal-numeric"
              value={projectDuration}
              onChange={onProjectDurationChange}
              placeholder="3-5 days"
              fullWidth
              margin="normal"
            />
    
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasTentativeDates}
                  onChange={onHasTentativeDatesChange}
                />
              }
              label="Has Tentative Dates"
            />
    
            {hasTentativeDates && (
              <div>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={onStartDateChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="normal"
                />
    
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={onEndDateChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="normal"
                />
              </div>
            )}
    
            <Button
              variant="contained"
              component="label"
            >
              Upload Images
              <input
                type="file"
                accept="image/*"
                hidden
                multiple
                onChange={imageUploaded}
              />
            </Button>
    
            <Button
              variant="contained"
              color="primary"
              onClick={createProposal}
              disabled={!canSubmit}
              margin="normal"
            >
              Create Proposal
            </Button>
          </form>
        </div>
        </Box>
        </ThemeProvider>
      );
      */
      return (
        <div>
            <div>
                <h1>Create Proposal</h1>
                <form>
                    <label>Full Name: </label>
                    <input className='create-proposal-string' value={customerName} onChange={onNameChange} placeholder='John Smith'></input>
                    <br/>
                    <label>Address: </label>
                    <input size={50} className='create-proposal-address ' value={customerAddress} onChange={onAddressChange} placeholder='123 Main Street, Anytown, USA 12345'></input>
                    <br/>
                    <label>Area Being Renovated: </label>
                    <input className='create-proposal-string' value={areaRennovated} onChange={onAreaRennovateChange} placeholder='patio'></input>
                    <br/>
                    <label>Square footage of concrete installation (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={areaOfConcreteInstallation} onChange={onAreaOfConcreteInstallationChange} placeholder='123'></input>
                    <br/>
                    <label>Square footage of demolition/excavation (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={areaOfDemolition} onChange={onAreaOfDemolitionChange} placeholder='123'></input>
                    <br/>
                    <label>Thickness of concrete slab: </label>
                    <input className='create-proposal-numeric' value={thicknessOfConcrete} onChange={onThicknessOfConcreteChange} placeholder='1 In.'></input>
                    <br/>
                    <label>Concrete mix PSI strength (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={concretePSIStrength} onChange={onConcretePsiChange} placeholder='1234'></input>
                    <br/>
                    <label>Control joint material: </label>
                    <input className='create-proposal-string' value={controlJointMaterial} onChange={onControlJointMaterialChange} placeholder='Treated Wood'></input>
                    <br/>
                    <label>Rebar number/sizing (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={rebarNumber} onChange={onRebarNumberChange} placeholder='1'></input>
                    <br/>
                    <label>Rebar spacing (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={rebarSpacing} onChange={onRebarSpacingChange} placeholder='12'></input>
                    <br/>
                    <label>Surface Finishing: </label>
                    <input className='create-proposal-string' value={surfaceFinishing} onChange={onSurfaceFinishingChange} placeholder='Moderate Slip Resistance'></input>
                    <br/>
                    <label>Additional Notes: </label>
                    <input className='create-proposal-string' value={additionalNotes} onChange={onAdditionalNotesChange} placeholder='3YDS'></input>
                    <br/>
                    <label>Concrete Slab Installation Cost (e.g 1234): </label>
                    <input className='create-proposal-numeric' type='number' inputMode='numeric' value={installationCost} onChange={onInstallationCostChange} placeholder='1234'></input>
                    <br/>
                    <label>Is Demolition Included: </label>
                    <input value={isDemolitionIncluded} onChange={onIsDemolitionIncludedChange} type='checkbox' checked={isDemolitionIncluded}></input>
                    <br/>
                    {!isDemolitionIncluded &&
                    <div>
                        <label>Demolition Cost (e.g 1234): </label>
                        <input className='create-proposal-numeric' type='number' inputMode='numeric' value={demolitionCost} onChange={onDemolitionCostChange} placeholder='1234'></input>
                        <br/>
                    </div>}
                    <label>Is Disposal Included</label>
                    <input value={isDisposalIncluded} onChange={onIsDisposalIncludedChange} type='checkbox' checked={isDisposalIncluded}></input>
                    <br/>
                    {!isDisposalIncluded &&
                    <div>
                        <label>Disposal Cost (e.g 1234): </label>
                        <input className='create-proposal-numeric' type='number' inputMode='numeric' value={disposalCost} onChange={onDisposalCostChange} placeholder='1234'></input>
                        <br/>
                    </div>}
                    <label>Is HOA Assistance Included</label>
                    <input value={isHoaAssistanceIncluded} onChange={onIsHoaAssistanceIncluded} type='checkbox' checked={isHoaAssistanceIncluded}></input>
                    <br/>
                    {!isHoaAssistanceIncluded &&
                    <div>
                        <label>HOA Assistance Cost (e.g 1234): </label>
                        <input className='create-proposal-numeric' type='number' inputMode='numeric' value={hoaAssistanceCost} onChange={onHoaAssistanceCostChange} placeholder='1234'></input>
                        <br/>
                    </div>}
                    <label>Has Design/Special Requests</label>
                    <input value={hasSpecialDesign} onChange={onHasSpecialDesignChange} type='checkbox' checked={hasSpecialDesign}></input>
                    <br/>
                    {hasSpecialDesign &&
                    <div>
                        <label>Special Design Cost (e.g 1234): </label>
                        <input className='create-proposal-numeric' type='number' inputMode='numeric' value={specialDesignCost} onChange={onSpecialDesignCostChange} placeholder='1234'></input>
                        <br/>
                    </div>}
                    <label>Is Financed?</label>
                    <input type="checkbox" value={isFinanced} onChange={onIsFinanceChanged}></input>
                    <br/>
                    {isFinanced &&
                        <div>
                            <label>Monthly Payment (e.g 1234)</label>
                            <input className='create-proposal-numeric' type="number" inputMode='numeric' value={monthlyCost} onChange={onMonthlyCostChange} placeholder='1234'></input>
                            <br/>
                        </div> }
                </form>
                <label>Give Discount: </label>
                <input type="checkbox" onChange={onIsDicountChange}></input>
                <br/>
                {isDiscount &&
                <div>
                    <label>Discount Type: </label>
                    <select value={discountType} name='dicount_type' onChange={onDicountTypeChange}>
                        <option value={''}></option>
                        <option value={'other'}>Other</option>
                        <option value={"Friends & Family"}>Friends and Family</option>
                        <option value={"Military"}>Military</option>
                        <option value={"Referral"}>Referral</option>
                    </select>
                    <br/>
                    {isOtherDiscount &&
                    <div>
                        <label>Other Discount Type</label>
                        <input className='create-proposal-string' value={discountType} onChange={onOtherDiscountTypeChange}></input>    
                    </div>}
                    <label>Discount Amount</label>
                    <input type='number' inputMode='numeric' value={discountAmount} onChange={onDiscountAmountChange} placeholder='1234'></input>
                    <br/>
                </div>
                }
                <label>Project Duration: </label>
                <input className='create-proposal-numeric' value={projectDuration} onChange={onProjectDurationChange} placeholder='3-5 days'></input>
                <br/>

                <label>Has Tentative Dates: </label>
                <input type="checkbox" onChange={onHasTentativeDatesChange} checked={hasTentativeDates}></input>
                <br/>
                {hasTentativeDates &&
                <div>
                    <label>Start Date: </label>
                    <input type='date' value={startDate} onChange={onStartDateChange}></input>
                    <br/>
                    <label>End Date: </label>
                    <input type='date' value={endDate} onChange={onEndDateChange}></input>
                    <br/>    
                </div>}

                <input
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    onChange={imageUploaded}
                    multiple
                />
                <br/>
                <button onClick={createProposal} disabled={!canSubmit}>Create Proposal</button>
            </div>
        </div>
    );

} 

export default CreateProposal;