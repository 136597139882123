import logo from './logo.svg';
import './App.css';
import CreateProposal from './components/CreateProposal/CreateProposal';
import LoginPage from './components/LoginPage/LoginPage';
import LandingPage from './components/LandingPage/LandingPage';
import TestLoginPage from './components/LoginPage/TestLoginPage';
import React,{Component} from 'react';
import { BrowserRouter as Router, Routes, Route, HashRouter } from 'react-router-dom';
import AppLayoutWithBreadcrumbs from './components/helpers/AppLayoutWithBreadcrumbs';
import HomePage from './components/home/HomePage';
import ViewProposal from './components/ViewProposal/ViewProposal.tsx';
import AddCustomer from './components/AddCustomers/AddCustomer.tsx';
import ViewCustomers from './components/ViewCustomer/ViewCustomers.tsx';
import CustomerLocation from './components/MapView/CustomerLocation.tsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<LandingPage/>}></Route>
        <Route path='/login' element={<TestLoginPage/>}/>
        <Route path='/home' element={<AppLayoutWithBreadcrumbs component={<HomePage/>}/>}/>
        <Route path='/create_proposal' element={<AppLayoutWithBreadcrumbs component={<CreateProposal/>}/>}></Route>
        <Route path='/view_proposals' element={<AppLayoutWithBreadcrumbs component={<ViewProposal/>}/>}></Route>
        <Route path='/add_customer' element={<AppLayoutWithBreadcrumbs component={<AddCustomer/>}/>}></Route>
        <Route path='/view_customers' element={<AppLayoutWithBreadcrumbs component={<ViewCustomers/>}/>}></Route>
        <Route path='/mapView' element={<AppLayoutWithBreadcrumbs component={<CustomerLocation/>}/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
