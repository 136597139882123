import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { MonthlyProfit } from "./MonthlyDataModelResponse";

export default function ViewProfits() {
      
    const [monthlyData, setMonthlyProfit] = useState([]);
    
    useEffect(() => {
        async function getMonthlyProfit() {
            const response = await fetch(process.env.REACT_APP_GET_MONTHLY!, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            const data = await response.json();
            setMonthlyProfit(data.monthlyProfits);
            console.log(data.monthlyProfits);
        }
        getMonthlyProfit();
    }, []);

    return (
        <BarChart
            dataset={monthlyData}
            height={250}
            width={450}
            xAxis={[{scaleType: 'band', dataKey: 'month'}]}
            series={[
                {dataKey: 'data'}
            ]}
        >

        </BarChart>
        
    )

}